<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div class="curriculum-wrapper">
		<div
			class="d-flex curriculum-header"
			:class="
				isSmAndDown
					? 'flex-column align-start full-width'
					: 'justify-space-between align-center'
			"
		>
			<div
				class="title d-flex align-center"
				:class="{
					isSmAndDown: 'justify-space-between full-width',
				}"
			>
				<strong>교육 과정</strong>
				<div>
					<v-btn
						@click="
							isOpenMyPageLicenseCurriculumDialog =
								!isOpenMyPageLicenseCurriculumDialog
						"
						color="primary"
						class="ml-2 mr-1"
						:class="isSmAndDown ? 'pl-4' : 'pl-3'"
					>
						<v-icon class="">mdi-text-box-outline</v-icon>
						강의 정보
					</v-btn>
					<v-btn
						color="accent"
						class="font-weight-bold"
						@click="routeToLicenseExamGuide"
					>
						시험 응시하기
					</v-btn>
				</div>
			</div>

			<div
				:class="{ 'd-flex justify-space-between mt-3': isSmAndDown }"
				:style="{ 'width: 100%': isSmAndDown }"
			>
				<span>
					<span :class="isSmAndDown ? 'text-xs' : 'text-sm'">총</span>
					<strong style="color: #56ca00">{{ totalLectureCompletion }}</strong>
					/ {{ lectures.length }}
					<span :class="isSmAndDown ? 'text-xs' : 'text-sm'">강의</span>
				</span>
				<span>
					<span :class="isSmAndDown ? 'text-xs' : 'text-sm'">강의시간</span>
					{{ totalRunningTime | secondTohhmmss }}
				</span>
				<span>
					<span :class="isSmAndDown ? 'text-xs' : 'text-sm'">완료율</span>
					<strong style="color: #56ca00">
						{{
							isCourseFinished
								? 100
								: Math.floor((totalWatched / totalRunningTime) * 100) || '-'
						}}
					</strong>
					<span :class="isSmAndDown ? 'text-xs' : 'text-sm'" class="ml-0">
						%
					</span>
				</span>
			</div>
		</div>
		<div class="grid-container curriculum-content">
			<template v-if="lectures.length > 0">
				<v-card
					v-for="(lecture, index) in lectures"
					:key="'lecture-' + index"
					class="grid-item"
					:class="'grid-item-' + index"
					style="cursor: pointer; position: relative"
					:height="isSmAndDown ? 85 : 100"
					elevation="1"
					@click="handleClickLecture(lecture, index)"
					outlined
				>
					<div class="wrapper">
						<div class="left">
							<div class="title">
								<span>{{ lecture.title }}</span>
							</div>
							<div class="running-time">
								<v-icon>mdi-clock-time-five-outline</v-icon>
								<span class="ml-1">
									{{ lecture.vimeoVideoLengthInSec | secondTohhmmss }}
									<span class="mx-1">/</span>
									{{
										isFinished(lecture.watched, lecture.vimeoVideoLengthInSec)
											? 100
											: Math.floor(
													(lecture.watched / lecture.vimeoVideoLengthInSec) *
														100,
											  )
									}}
									%
								</span>
							</div>
						</div>
						<div class="right">
							<div class="course-completion">
								<v-icon
									large
									:color="
										isFinished(lecture.watched, lecture.vimeoVideoLengthInSec)
											? '#56CA00'
											: '#d9d9d9'
									"
								>
									mdi-check-circle
								</v-icon>
							</div>
						</div>
					</div>
				</v-card>
			</template>
			<template v-else>
				<h4>등록된 강의가 없습니다</h4>
			</template>
		</div>
		<my-page-license-curriculum-dialog
			:is-open-my-page-license-curriculum-dialog.sync="
				isOpenMyPageLicenseCurriculumDialog
			"
		/>
	</div>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'

import { useRouter, getVuetify } from '@core/utils'
import { infoSwal } from '@/plugins/swalMixin'

import MarketCourseService from '@/services/MarketCourseService'

import MyPageLicenseCurriculumDialog from '../components/MyPageLicenseCurriculumDialog.vue'

const TIME_OFFSET = 3

export default {
	components: {
		MyPageLicenseCurriculumDialog,
	},
	props: {
		courseId: {},
		fetchLectures: {
			type: Boolean,
		},
		fetchNextLecture: {
			type: Boolean,
		},
	},
	setup(props, { emit }) {
		const $vuetify = getVuetify()
		const { router } = useRouter()
		const lectures = ref([])
		const isFetchLectures = ref(false)
		const registrationDetail = ref(null)
		const nextLectureIndex = ref(0)
		const totalLectureCompletion = ref(0)
		const totalWatched = ref(0)
		const totalRunningTime = ref(0)
		const isOpenMyPageLicenseCurriculumDialog = ref(false)
		const isCourseFinished = ref(false)

		const isFinished = (watched, videoLengthInsec) => {
			return videoLengthInsec - watched < TIME_OFFSET
		}

		const listMyMarketCourseLectures = async () => {
			try {
				isFetchLectures.value = true
				lectures.value = await MarketCourseService.listMyMarketCourseLectures(
					props.courseId,
				)

				totalWatched.value = 0
				totalRunningTime.value = 0
				totalLectureCompletion.value = 0
				for (let i = 0; i < lectures.value.length; i++) {
					if (lectures.value[i].finished) {
						totalLectureCompletion.value += 1
						totalWatched.value += parseInt(lectures.value[i].watched)
						nextLectureIndex.value = i > 0 ? i + 1 : 0
						if (nextLectureIndex.value === lectures.value.length) {
							nextLectureIndex.value = 0
						}
						continue
					}
				}

				for (let i = 0; i < lectures.value.length; i++) {
					totalRunningTime.value += lectures.value[i].vimeoVideoLengthInSec

					isCourseFinished.value = isFinished(
						lectures.value[i].watched,
						lectures.value[i].vimeoVideoLengthInSec,
					)
				}
				totalRunningTime.value = lectures.value.reduce(
					(accumulator, currentValue) =>
						accumulator + currentValue.vimeoVideoLengthInSec,
					0,
				)
			} catch (e) {
				console.error(e)
			} finally {
				isFetchLectures.value = true
			}
		}

		const scrollToSelectedElement = () => {
			const selectedElement =
				document.getElementsByClassName('grid-item selected')
			const topPos = selectedElement[0].offsetTop - 200
			document.getElementsByClassName('grid-container')[0].scrollTop = topPos
		}

		const getMyMarketCourseRegistrationDetail = async () => {
			try {
				const data =
					await MarketCourseService.getMyMarketCourseRegistrationDetail()
				registrationDetail.value = data.filter(
					e => e.marketCourse.id === parseInt(props.courseId),
				)
			} catch (e) {
				console.error(e)
			}
		}

		const handleClickLecture = async (item, index) => {
			try {
				const gridElements = document.getElementsByClassName('grid-item')
				for (const element of gridElements) {
					element.classList.remove('selected')
				}

				const gridElement = document.getElementsByClassName(
					'grid-item-' + index,
				)
				gridElement[0].classList.add('selected')
				emit('selected-lecture', item)
			} catch (e) {
				console.log(e)
			}
		}

		const routeToLicenseExamGuide = async () => {
			try {
				if (!isCourseFinished.value) {
					infoSwal('강의 수강이 완료되지 않았습니다')
					return
				}

				router.push({
					name: 'mypage-license-exam-guide',
					params: {
						courseId: props.courseId,
					},
				})
			} catch (e) {
				console.log(e)
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		onMounted(async () => {
			await getMyMarketCourseRegistrationDetail()
			await listMyMarketCourseLectures()
			handleClickLecture(
				lectures.value[nextLectureIndex.value],
				nextLectureIndex.value,
			)
			scrollToSelectedElement()
		})

		watch(
			() => props.fetchLectures,
			currentValue => {
				if (currentValue) {
					listMyMarketCourseLectures()
				}
			},
		)

		watch(
			() => props.fetchNextLecture,
			async () => {
				router.go()
			},
		)

		return {
			lectures,
			isSmAndDown,
			isFetchLectures,
			totalWatched,
			totalRunningTime,
			totalLectureCompletion,
			isOpenMyPageLicenseCurriculumDialog,

			isFinished,
			isCourseFinished,

			routeToLicenseExamGuide,
			handleClickLecture,
		}
	},
}
</script>
<style lang="scss" scoped>
.full-width {
	width: 100%;
	max-width: 100%;
}

.curriculum {
	&-header {
		margin-top: 15px;
		margin-bottom: 10px;

		span {
			font-size: 18px;
			margin: 0 6px;

			@media (max-width: 595px) {
				margin: 0 3px;
			}
		}
	}
}

.grid-container {
	display: grid;
	gap: 4px;
	column-gap: 8px;
	row-gap: 8px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-auto-rows: 1fr 1fr;
	max-height: 550px;
	overflow-y: scroll;

	@media (max-width: 595px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-auto-rows: 1fr;
		box-shadow: none;
		padding: 0;
	}

	.grid-item {
		&.selected {
			background-color: rgb(16, 44, 92, 0.75);

			.wrapper {
				.title,
				.running-time,
				.v-icon {
					color: white;
					font-weight: 600;
				}
			}
		}

		.wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			height: 100%;

			@media (max-width: 595px) {
				padding-top: 8px;
				padding-bottom: 8px;
			}

			.title {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				line-height: 1.3;
				font-size: 16px !important;
			}

			.running-time {
				font-size: 16px;
				margin-top: 4px;
			}

			.course-completion {
				margin-left: 4px;
			}
		}
	}
}
</style>
