<template>
	<div>
		<div class="title mb-2">
			<strong>{{ selectedLecture.title }}</strong>
		</div>
		<div class="vimeo-wrapper" :key="selectedLecture.vimeoVideoId">
			<div id="video-content"></div>
		</div>
	</div>
</template>
<script>
import { ref, watch, nextTick, computed } from '@vue/composition-api'

import Player from '@vimeo/player'

import store from '@/store'
import { infoSwal } from '@/plugins/swalMixin'

import MarketLectureService from '@/services/MarketLectureService'

export default {
	props: {
		selectedLecture: {
			required: true,
		},
	},
	setup(props, { emit }) {
		const player = ref(null)
		const timeWatched = ref(0)
		const myCurrentLecture = ref({})
		const checkInIntervalId = ref(null)
		const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])

		const createMyLecture = async () => {
			try {
				const payload = {
					uid: firebaseUser.value.uid,
					duration: props.selectedLecture.vimeoVideoLengthInSec,
					watched: 0,
					vimeoVideoId: props.selectedLecture.vimeoVideoId,
				}
				await MarketLectureService.createMyLecture(payload)
			} catch (e) {
				console.log('createMyLecture', e)
			}
		}

		const getMyLecture = async () => {
			try {
				const payload = {
					uid: firebaseUser.value.uid,
					vimeoVideoId: props.selectedLecture.vimeoVideoId,
				}
				myCurrentLecture.value = await MarketLectureService.getMyLecture(
					payload,
				)
				if (!myCurrentLecture.value) {
					await createMyLecture()
				}
			} catch (e) {
				console.log('getMyLecture', e)
			}
		}

		const updateMyLecture = async currentTime => {
			try {
				const payload = {
					uid: firebaseUser.value.uid,
					duration: props.selectedLecture.vimeoVideoLengthInSec,
					finished:
						props.selectedLecture.vimeoVideoLengthInSec - currentTime <= 2
							? true
							: false,
					watched: Math.floor(currentTime),
					vimeoVideoId: props.selectedLecture.vimeoVideoId,
				}
				await MarketLectureService.updateMyLecture(payload)
			} catch (e) {
				console.log('updateMyLecture', e)
			}
		}

		const loadVimeo = _selectedLecture => {
			try {
				const options = {
					id: _selectedLecture.vimeoVideoId,
					autopause: false,
					width: document.getElementById('video-content').offsetWidth,
					height: document.getElementById('video-content').offsetWidth / 2,
				}
				player.value = new Player('video-content', options)

				player.value.on('play', async () => {
					await getMyLecture()

					if (myCurrentLecture.value && !myCurrentLecture.value.finished) {
						checkInIntervalId.value = setInterval(() => {
							player.value.getCurrentTime().then(async currentTime => {
								// 1.5 업데이트 시간 오차 간격 줄이는데 활용
								if (currentTime > myCurrentLecture.value.watched + 1.5) {
									if (checkInIntervalId.value) {
										updateMyLecture(currentTime)
									}
								}
							})
						}, 1000)
					}
				})

				player.value.on('pause', () => {
					checkInIntervalId.value = null
					clearInterval(checkInIntervalId.value)
				})

				player.value.on('timeupdate', data => {
					if (
						data.seconds - 1 < timeWatched.value &&
						data.seconds > timeWatched.value
					) {
						timeWatched.value = data.seconds
					}
				})

				player.value.on('seeked', data => {
					if (timeWatched.value < data.seconds) {
						player.value.setCurrentTime(timeWatched.value)
					}
				})

				player.value.on('ended', async () => {
					checkInIntervalId.value = null
					clearInterval(checkInIntervalId.value)

					const confirm = await infoSwal({
						html: '강의를 모두 시청 하셨습니다.',
						showCancelButton: true,
						confirmButtonText: '다음 강의 보기',
						reverseButtons: true,
					})
					if (confirm.isConfirmed) {
						emit('fetch-next-lecture', true)
					}
				})
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => props.selectedLecture,
			async currentValue => {
				await getMyLecture()

				if (currentValue) {
					nextTick(() => {
						loadVimeo(currentValue)
					})
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => myCurrentLecture.value,
			currentValue => {
				if (currentValue) {
					if (currentValue.watched > 0) {
						timeWatched.value = currentValue.watched
						if (!currentValue.finished) {
							player.value.setCurrentTime(timeWatched.value)
						}
					}
				}
			},
		)

		return {}
	},
}
</script>
<style lang="scss" scoped>
.vimeo-wrapper {
	background-color: #f2f2f2;
}
</style>
