/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'

import { getDatabase, ref, get, set, update } from '@firebase/database'

const db = getDatabase()

const marketLectureService = {
	listMarketLectures() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-lectures',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLecture(marketLectureId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-lectures/${marketLectureId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMarketLectureDetail(marketLectureId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-lectures/${marketLectureId}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},

	createMyLecture(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const lectureRef = ref(
					db,
					`lectures/${payload.uid}/${payload.vimeoVideoId}`,
				)
				get(lectureRef).then(snapshot => {
					if (!snapshot.exists()) {
						set(lectureRef, {
							datetimeUtc: new Date().toISOString(),
							duration: payload.duration,
							finished: false,
							uid: payload.uid,
							watched: payload.watched,
						})
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMyLecture(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const lectureRef = ref(
					db,
					`lectures/${payload.uid}/${payload.vimeoVideoId}`,
				)

				get(lectureRef).then(snapshot => {
					if (snapshot.exists()) {
						const data = snapshot.val()
						resolve(data)
					} else {
						resolve(null)
					}
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateMyLecture(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const lectureRef = ref(
					db,
					`lectures/${payload.uid}/${payload.vimeoVideoId}`,
				)
				get(lectureRef).then(snapshot => {
					if (snapshot.exists()) {
						update(lectureRef, {
							datetimeUtc: new Date().toISOString(),
							duration: payload.duration,
							uid: payload.uid,
							finished: payload.finished,
							watched: payload.watched,
						})
					}
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketLectureService
